/**
 * green: #288d1f;
 * blue: #169be9;
 * orange: #ff9603;
 */

html, body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 21px;
  color: #333;
}

p {
  font-size: 21px;
  color: #333;
}

a {
  color: #ff9603;
}

a:hover {
  color: #ff9603;
  text-decoration: underline;
}